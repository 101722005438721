var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"id":"app-bar","absolute":"","app":"","color":"transparent","flat":"","height":"75"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){_vm.$vuetify.breakpoint.smAndDown ? _vm.setDrawer(!_vm.drawer) : _vm.$emit('input', !_vm.value)}}},[(_vm.value)?_c('v-icon',[_vm._v("mdi-view-quilt")]):_c('v-icon',[_vm._v("mdi-dots-vertical")])],1),_c('v-toolbar-title',{staticClass:"hidden-sm-and-down font-weight-light",domProps:{"textContent":_vm._s(_vm.title)}}),_c('v-spacer'),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("far fa-list-check")])],1)]}}])},[_c('v-list',{attrs:{"flat":""}},_vm._l((_vm.displayTypes),function(t){return _c('v-list-item',{key:t.value},[_c('v-checkbox',{attrs:{"input-value":_vm.isVisible(t.value),"label":t.text},on:{"change":function (active) { return _vm.changeVisible(t.value, active); },"click":function($event){$event.stopPropagation();}}})],1)}),1)],1),_c('v-btn',{staticClass:"ml-2",attrs:{"min-width":"0","text":"","href":"/#/"}},[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),(!_vm.profile._id)?_c('v-btn',{staticClass:"ml-2",attrs:{"min-width":"0","text":"","to":"/login"}},[_c('v-icon',[_vm._v("far fa-user")])],1):_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-user")])],1)]}}])},[_c('v-list',{attrs:{"tile":false,"flat":"","nav":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:{red: hover},attrs:{"to":"/profile"}},[_vm._v(" Profil ")])]}}])}),_c('v-divider',{staticClass:"mb-2 mt-2"}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:{red: hover},on:{"click":_vm.logout}},[_vm._v(" Logout ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }